<template>
  <div v-show="show">
    <div :ref="(el) => heightRef = el" class="hide-print">
      <UITabs
        v-if="tab !== 2"
        v-model="tab"
        class-button="block my-2"
        class="flex justify-center w-full"
        hide-active
        :tabs="tabs"
      />

      <div v-if="tab === 1 && templateUrl" class="text-right px-2 mb-2">
        <a :href="templateUrl" target="_blank">Open in een nieuw venster</a>
      </div>

      <div v-if="tab === 2" class="py-2">
        <div v-if="errorLoading || done" class="flex flex-col">
          <p v-if="errorLoading" class="text-red-500">
            <i class="fas fa-times"></i> {{ errorLoading }}
          </p>
          <p v-else-if="done">
            <i class="text-green-500 fas fa-check"></i> Ondertekend en opgeslagen. {{ redirect ? 'Je wordt zo dadelijk doorgestuurd.' : 'Je kan dit venster nu veilig sluiten.' }}
          </p>
        </div>
        <div class="flex gap-4 justify-center">
          <button class="btn error" @click="() => tab = 1">
            Annuleren
          </button>
          <button class="btn info" @click="resetCanvas">
            Opnieuw
          </button>
          <button class="btn success" @click="submitCanvas">
            Opslaan
          </button>
        </div>
        <p v-if="error" class="bg-red-500 text-white p-2 px-4 w-full rounded block">
          {{ error }}
        </p>
      </div>
    </div>
    <div v-show="tab === 1" :key="imageSrc" class="w-full">
      <slot :image-src="done ? imageSrc : ''" :tekenen="() => tab = 2">
        <embed v-if="templateUrl" :src="templateUrl" border="0">
      </slot>
    </div>
    <div v-show="tab === 2" class="tekenen-wrapper flex flex-col gap-2 justify-center items-center bg-gray-200">
      <div v-show="!errorLoading && !done" ontouchmove="return false" class="signpad-wrapper bg-gray-100">
        <canvas id="canvas" :ref="(el) => canvas = el"></canvas>
        <canvas id="resizer" :ref="(el) => resizer = el"></canvas>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useFullscreen } from '@vueuse/core'
import { useHead } from '@vueuse/head'

import { axiosClient } from '@/plugins/axios.js'
import router from '@/router'

import UITabs from '@/components/UI/Tabs.vue'

import useApi from '@/hooks/useApi'
import useReloadBrowserTabs from '@/hooks/useReloadBrowserTabs'
import useWindow from '@/hooks/useWindow'
// import { useWindowSize } from '@vueuse/core'

const props = defineProps({
  title: String,
  apiTemplateFn: Function,
  apiImageFn: Function,
  apiSignFn: Function,
  redirect: Boolean,
  fileName: String,
})

const head = ref({
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/signature_pad@2.3.2/dist/signature_pad.min.js',
      onload: () => setImmediate(resetCanvas),
    },
  ],
})
useHead(head)

const route = useRoute()
const store = useStore()
const api = useApi()

const { enter: enterFullscreen, exit: exitFullscreen } = useFullscreen()

const tab = ref(1)
const tabs = computed(() => {
  const result = ['Terug naar dashboard', 'Lezen']
  if (tab.value !== 2) {
    result.push('Tekenen')
  }
  return result
})
watch(tab, async () => {
  if (tab.value === 0) {
    router.push('/')
    return
  }

  if (tab.value === 2) {
    done.value = false
    await enterFullscreen()
    await screen?.orientation?.lock?.('landscape')
    setTimeout(() => resetCanvas(), 60)
    return
  }

  await exitFullscreen()
  await screen?.orientation?.lock?.('any')
})
// watch(() => tab.value, (val, oldval) => {
//   console.log({ val, oldval })
//   if (val > 0 && oldval < 1) {
//     done.value = false
//     imageSrc.value = ''
//     setTimeout(() => resetCanvas(), 1)
//   }
// })

const templateUrl = ref('')

const imageSrc = ref('')
const show = ref(false)
const done = ref(false)
const error = ref('')
const errorLoading = ref('')
if (!props.title || !props.apiSignFn) {
  errorLoading.value = 'Dit is een verkeerde link voor handtekening! Probeer het later nog eens.'
}

const heightRef = ref(null)
// const windowSize = useWindowSize()
const heightEmbedHeading = computed(() => `calc(100vh - ${heightRef?.value?.innerHeight || heightRef?.value?.clientHeight || heightRef?.value?.height || 50}px)`)

const canvas = ref(null)
const resizer = ref(null)
const signpad = ref(null)

const imageApiUrl = computed(() => {
  if (!useWindow()) return ''
  const url = new URL(window.location.origin + props.apiImageFn(route))
  if (props.fileName) {
    url.searchParams.set('fileName', props.fileName)
  }
  return url.toString().replace(window.location.origin, '')
})

const reloadTabs = useReloadBrowserTabs()

const handleDone = () => {
  imageSrc.value = `${imageApiUrl.value}&_t=${Date.now()}`
  done.value = true
  if (props.redirect) {
    setTimeout(() => {
      window.location.href = `${window.location.pathname}/document`.replace('//', '/')
    }, 100)
  } else {
    tab.value = 1
  }
}

const onSubmit = blob => {
  error.value = ''

  const formData = new FormData()
  formData.append('file', blob, `${props.fileName || store.state.profile.id}.png`)

  axiosClient.post(props.apiSignFn(route), formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data;',
      'cache-control': 'no-cache',
    },
  }).then(() => {
    reloadTabs()
    handleDone()
    done.value = true
  }).catch(err => {
    console.error(err)
    error.value = 'Er liep iets verkeerd, probeer het opnieuw.'
  })
}

onMounted(async () => {
  await api.get(imageApiUrl.value, {}, { responseType: 'arraybuffer' }).then(({ data: signatureBuffer }) => {
    if (signatureBuffer.byteLength > 0) {
      return handleDone()
    }
  }).finally(() => {
    show.value = true
    return
  })

  if (!show.value) return

  if (props.apiTemplateFn) {
    await api.get(props.apiTemplateFn(route), { url: true }).then(({ data }) => {
      if (data.url) {
        templateUrl.value = data.url
      }
    })
  }

  resetCanvas()

  if (!useWindow()) return
  window.addEventListener('resize', () => {
    setTimeout(() => resetCanvas(), 1)
  })
})

// --- Canvas functions

const trimCanvas = canvas => {
  const ctx = canvas.getContext('2d')
  const copy = document.createElement('canvas').getContext('2d')
  const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height)
  const bound = { top: null, left: null, right: null, bottom: null }
  const l = pixels.data.length
  let i
  let x
  let y

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % canvas.width
      y = ~~((i / 4) / canvas.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.left === null) {
        bound.left = x
      } else if (x < bound.left) {
        bound.left = x
      }

      if (bound.right === null) {
        bound.right = x
      } else if (bound.right < x) {
        bound.right = x
      }

      if (bound.bottom === null) {
        bound.bottom = y
      } else if (bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  // Calculate the height and width of the content
  const trimHeight = bound.bottom - bound.top
  const trimWidth = bound.right - bound.left
  const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight)

  copy.canvas.width = trimWidth
  copy.canvas.height = trimHeight
  copy.putImageData(trimmed, 0, 0)

  // Return trimmed canvas
  return copy.canvas
}

const resetCanvas = () => {
  if (canvas.value) {
    canvas.value.width = Math.max(320, Math.min(420 * 1.5, window.innerWidth - 10))
    canvas.value.height = canvas.value.width / 420 * 150
  }

  if (!signpad.value) {
    signpad.value = new window.SignaturePad(canvas.value, {
      minDistance: 0,
      throttle: 6,
      minWidth: 0.75,
      maxWidth: 1.5,
      penColor: 'rgb(37, 61, 121)',
    })
  }
  signpad.value.clear()
}

const submitCanvas = () => {
  const QUALITY = 0.7
  const dataURL = signpad.value.toDataURL('image/png', QUALITY)

  const ratio = (420 / canvas.value.width)
  try {
    const img = document.createElement('img')
    img.onload = () => {
      resizer.value.width = (canvas.value.width * ratio) + 5
      resizer.value.height = (canvas.value.height * ratio) + 5
      const ctx = resizer.value.getContext('2d')
      ctx.drawImage(img, 0, 0, resizer.value.width, resizer.value.height)
      const copyCanvas0 = trimCanvas(resizer.value)
      copyCanvas0.toBlob(onSubmit, 'image/png', QUALITY)
    }
    img.src = dataURL
    return
  } catch (e1) {
    console.error('SubmitCanvas error-1', e1)
  }

  try {
    const copyCanvas2 = trimCanvas(canvas.value)
    copyCanvas2.toBlob(onSubmit, 'image/png', QUALITY)
    return
  } catch (e2) {
    console.error('SubmitCanvas error-2', e2)
    onSubmit(signpad.value.toDataBlob('image/png', QUALITY))
  }
}
</script>

<style scoped>
embed, .tekenen-wrapper {
  width: 100vw;
  height: v-bind(heightEmbedHeading);
}

.signpad-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

#canvas {
  border: 1px solid black;
}

#resizer {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -200vh;
  right: -200vw;
}
</style>
