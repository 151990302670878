import { watch } from 'vue'
import { useBroadcastChannel } from '@vueuse/core'

export default () => {
  const { isSupported, data, post } = useBroadcastChannel({ name: 'vueuse-demo-channel' })
  watch(data, (val, oldval) => {
    if (val != oldval) {
      window.location.href = String(window.location.href)
    }
  })

  return () => {
    if (isSupported) post(Date.now())
  }
}
