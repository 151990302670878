import useWindow from '@/hooks/useWindow'

export default () => {
  const browserObj = {}

  if (!useWindow()) return browserObj

  const ua = window.navigator.userAgent
  if (!ua) return browserObj

  // Safari 3.0+
  const hasSafariPush = window.safari?.pushNotification?.toString() === '[object SafariRemoteNotification]'
  browserObj.isSafari = /constructor/i.test(window.HTMLElement) || hasSafariPush
  // Chrome
  browserObj.isChrome = /Google Inc/.test(navigator.vendor)
  // Chrome on iOS
  browserObj.isChromeIOS = /CriOS/.test(ua)
  // Android
  browserObj.isAndroid = ua.includes('android') || ua.includes('Android')
  // iOS
  browserObj.isIOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream
  // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
  browserObj.isOpera = !!window.opera || ua.includes(' OPR/')
  // Firefox 1.0+
  browserObj.isFirefox = /Firefox/.test(ua)
  // Edge 20+
  browserObj.isEdge = /Edge/.test(ua)
  // Internet Explorer 6-11
  browserObj.isIE = false
  if (
    !browserObj.isSafari
    && !browserObj.isChrome
    && !browserObj.isChromeIOS
    && !browserObj.isIOS
    && !browserObj.isOpera
    && !browserObj.isFirefox
    && !browserObj.isEdge
  ) {
    browserObj.isIE = /* @cc_on!@ */ false || !!document.documentMode
  }

  // eslint-disable-next-line no-use-before-define
  browserObj.meta = browserSpecs(ua)
  browserObj.meta.userAgent = browserObj.meta.ua = ua
  browserObj.meta.vendor = navigator.vendor || undefined

  return browserObj
}

/**
 * https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
 */
const browserSpecs = ua => {
  let tmp
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

  if (/trident/i.test(M[1])) {
    tmp = /\brv[ :]+(\d+)/.exec(ua) || []
    return { name: 'IE', version: tmp[1] || '' }
  }

  if (M[1] === 'Chrome') {
    tmp = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tmp != null) {
      return { name: tmp[1].replace('OPR', 'Opera'), version: tmp[2] }
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  tmp = ua.match(/version\/(\d+)/i)
  if (tmp != null) {
    M.splice(1, 1, tmp[1])
  }

  return { name: M[0], version: M[1] }
}
